(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("HTML5Backend", [], factory);
	else if(typeof exports === 'object')
		exports["HTML5Backend"] = factory();
	else
		root["HTML5Backend"] = factory();
})(window, function() {
return 